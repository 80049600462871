import {
  createTheme,
  PaletteColorOptions,
  PaletteOptions,
  responsiveFontSizes,
  SimplePaletteColorOptions,
  ThemeOptions,
  TypographyVariantsOptions,
  TypographyStyle,
} from '@mui/material/styles';
import settings from './settings';

// Information on module augmentation with mui: https://mui.com/material-ui/customization/theming/#custom-variables
declare module '@mui/material/styles' {
  interface Palette {
    commom: CommonColors;
    tertiary: PaletteColor;
    quaternary: PaletteColor;
    quinary: PaletteColor;
    highlight: PaletteColor;
  }

  interface CommonColors {
    blue: string;
    red: string;
    green: string;
  }

  // TODO check if properties really have to be optional
  interface PaletteColor {
    contrastHighlight?: string;
    contrastError?: string;
    contrastTextDisabled?: string;
  }

  // eslint-disable-next-line no-shadow
  interface PaletteOptions {
    tertiary?: PaletteColorOptions;
    quaternary?: PaletteColorOptions;
    quinary?: PaletteColorOptions;
    highlight?: PaletteColorOptions & { contrastTextDisabled?: string };
  }

  // eslint-disable-next-line no-shadow
  interface SimplePaletteColorOptions {
    contrastHighlight?: string;
    contrastError?: string;
    contrastTextDisabled?: string;
  }

  interface TypeBackground {
    light: string;
  }
  interface TypographyVariants {
    fontFamily: string;
    fontFamilyText: string;
    fontFamilyPlots: string;
    h1: TypographyStyle;
    h2: TypographyStyle;
    h3: TypographyStyle;
    h4: TypographyStyle;
    h5: TypographyStyle;
    h6: TypographyStyle;
    button: TypographyStyle;
    body1: TypographyStyle;
    body2: TypographyStyle;
  }

  // allow configuration using `createTheme`
  // eslint-disable-next-line no-shadow
  interface TypographyVariantsOptions {
    fontFamily?: string;
    fontFamilyText?: string;
    fontFamilyPlots?: string;
    h1?: TypographyStyle;
    h2?: TypographyStyle;
    h3?: TypographyStyle;
    h4?: TypographyStyle;
    h5?: TypographyStyle;
    h6?: TypographyStyle;
    button?: TypographyStyle;
    body1?: TypographyStyle;
    body2?: TypographyStyle;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    fontFamily: true;
    fontFamilyText: true;
    fontFamilyPlots: true;
    h1: true;
    h2: true;
    h3: true;
    h4: true;
    h5: true;
    h6: true;
    button: true;
    body1: true;
    body2: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
    quaternary: true;
    quinary: true;
    highlight: true;
  }
}
declare module '@mui/material/TextField' {
  interface TextFieldPropsColorOverrides {
    tertiary: true;
    quaternary: true;
    quinary: true;
    highlight: true;
  }
}

/**
 * This is the main styling theme, which defines the basic variables for styles in the different components.
 * We might have different themes for different frontend apps later to change styles per app or client.
 * See https://www.styled-components.com/docs/advanced#theming
 */

/**
 * TODO:
 * color.common.white and color.text.primary change to primary.contrastText
 * redefine global font-size
 * redefine header sizes
 * change "em" to "rem"
 * replace fontSizes by mui typography, use header tag more often or define new fontSizes for buttons?
 * define fontSize for different screen sizes?
 * check usage of text.disabled color, at some points we have to use action.disabled or else
 * colorDarkGrey needs better replacement than grey.600 --> redefine grey color object
 * ...
 */

/**
 * MUI Theme
 * The color objects primary, secondary, tertiary, quarternary and highlight define
 * main, light and dark backgrounds and supply different constrastText colors.
 * That means, that the object's main, light and dark keys
 * may ONLY be use for background-colors and NOT colors attribute!
 * For colors always use the contrast text colors!
 * In the default tlk theme the highlight text colors are linked to
 * the highlight background colors, but that needn't be true for other themes.
 */

/**
 * The defined theme object (e.g. tlkTheme) is passed to the self-written function createMuiTheme, in which background,
 * text colors and the contrastHighlight color inside the primary, secondary, etc. color definitions
 * are set to a default value if not specifically defined.
 * Default values: highlight.main for contrastHighlight in primary, secondary, tertiary and quaternary
 * primary.main for background.paper, secondary.main for background.default and tertiary.main for background.light
 * text.primary for primary.contrastText, text.secondary for secodnary.contrastText and text.disabled
 * for primary.contrastTextDisabled
 */
const tlkTheme = {
  palette: {
    mode: 'dark',
    common: {
      black: '#000',
      white: '#fff',
      blue: '#009ABC',
      red: '#981e32',
      green: '#4caa6f',
    },
    primary: {
      main: '#2B3639',
      contrastText: '#fff',
      contrastTextDisabled: '#959a9c',
      contrastError: '#e8333b',
    },
    secondary: {
      main: '#20282a',
      contrastText: '#fff',
      contrastTextDisabled: '#959a9c',
      contrastError: '#ED6167',
    },
    tertiary: {
      main: '#EFEFEF',
      contrastText: '#151a1c',
      contrastTextDisabled: '#2B3639',
      contrastHighlight: '#009ABC',
      contrastError: '#e8333b',
    },
    quaternary: {
      main: '#959a9c',
      dark: '#6f7e8c',
      contrastText: '#151a1c',
      contrastTextDisabled: '#2B3639',
      contrastError: '#e8333b',
    },
    quinary: {
      main: '#fff',
      contrastText: '#151a1c',
      contrastTextDisabled: '#2B3639',
      contrastError: '#e8333b',
    },
    highlight: {
      main: '#009ABC',
      dark: '#007089',
      light: '#00c4ef',
      contrastText: '#fff',
      contrastTextDisabled: '#20282a',
    },
    error: {
      main: '#ED6167',
      dark: '#e8333b',
      light: '#f28f93',
      contrastText: '#fff',
      contrastTextDisabled: '#151a1c',
    },
    warning: {
      main: '#FFC857',
      dark: '#ffb724',
      light: '#ffd98a',
      contrastText: '#151a1c',
    },
    success: {
      main: '#4caa6f',
      dark: '#3c8758',
      light: '#6cbd8a',
      contrastText: '#fff',
    },
    grey: {
      50: '#F3F6F9',
      100: '#E7EBF0',
      200: '#E0E3E7',
      300: '#CDD2D7',
      400: '#B2BAC2',
      500: '#A0AAB4',
      600: '#6F7E8C',
      700: '#3E5060',
      800: '#2D3843',
      900: '#1A2027',
      mui: '#5c6567',
    },
    divider: '#959a9c',
    action: {
      hover: 'rgba(255, 255, 255, 0.08)',
      hoverOpacity: 0.08,
      disabled: 'rgba(255, 255, 255, 0.3)',
      disabledBackground: 'rgba(255, 255, 255, 0.12)',
      disableOpacity: 0.38,
    },
  },
  typography: {
    fontFamily: 'vistasansmed, Helvetica, Arial, Verdana, sans-serif',
    fontFamilyText: 'vistasansbook, Helvetica, Arial, Verdana, sans-serif',
    fontFamilyPlots: 'robotolight, Helvetica, Arial, Verdana, sans-serif',
    // h-tags are given their html-default values in "rem" instead of "em"
    h1: {
      fontSize: '2rem',
    },
    h2: {
      fontSize: '1.5rem',
    },
    h3: {
      fontSize: '1.17rem',
    },
    h4: {
      fontSize: '1rem',
    },
    h5: {
      fontSize: '0.83rem',
    },
    h6: {
      fontSize: '0.67rem',
    },
    button: {
      fontSize: '0.875rem',
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.875rem',
    },
  },
  zIndex: {
    sidePanel: 1100,
    collapseMenuButton: 1050,
    toaster: 9999, // value not passed to toaster component, but it is the default value defined toastify
  },
} as const;

// TODO: change the primary.contrastTextDisabled as it is the same as secondary.main
const zieglersimTheme = {
  palette: {
    ...tlkTheme.palette,
    primary: {
      main: '#A2C617',
      contrastText: '#151a1c',
      contrastTextDisabled: '#4A4A49',
    },
    secondary: {
      main: '#4A4A49',
      contrastText: '#fff',
      contrastHighlight: '#A2C617',
      contrastTextDisabled: '#959a9c',
    },
    tertiary: { ...tlkTheme.palette.tertiary, contrastHighlight: '#69800e' },
    quaternary: { ...tlkTheme.palette.quaternary, contrastHighlight: '#A2C617' },
    highlight: {
      main: '#fff',
      contrastText: '#151a1c',
      contrastTextDisabled: '#4A4A49',
    },
  },
  typography: tlkTheme.typography,
  zIndex: tlkTheme.zIndex,
};

const heatensimTheme = {
  palette: {
    ...tlkTheme.palette,
    primary: {
      main: '#fff',
      contrastText: '#000000',
      contrastTextDisabled: '#a39c95',
    },
    secondary: {
      main: '#f6f6f6',
      contrastText: '#000000',
      contrastTextDisabled: '#a39c95',
    },
    tertiary: {
      main: '#f6f6f6',
      contrastText: '#000000',
      contrastTextDisabled: '#a39c95',
      contrastHighlight: '#1e2865',
    },
    quaternary: {
      main: '#C1C1C1',
      contrastHighlight: '#1e2865',
    },
    highlight: {
      main: '#1e2865',
      contrastText: '#fff',
      contrastTextDisabled: '#a39c95',
    },
  },
  typography: tlkTheme.typography,
  zIndex: tlkTheme.zIndex,
};

const pluggsimTheme = {
  palette: {
    ...tlkTheme.palette,
    primary: {
      main: '#005184',
      contrastText: '#fff',
      contrastHighlight: '#fff',
      contrastTextDisabled: '#B2BAC2',
    },
    secondary: {
      main: '#f6f6f6',
      contrastText: '#005184',
      contrastHighlight: '#005184',
      contrastTextDisabled: '#4A4A49',
    },
    tertiary: { ...tlkTheme.palette.tertiary, contrastHighlight: '#005184' },
    quaternary: { ...tlkTheme.palette.quaternary, contrastHighlight: '#005184' },
    highlight: {
      main: '#fff',
      contrastText: '#151a1c',
      contrastTextDisabled: '#4A4A49',
    },
  },
  typography: tlkTheme.typography,
  zIndex: tlkTheme.zIndex,
};

const airflowTheme = {
  palette: {
    ...tlkTheme.palette,
    highlight: {
      main: '#0081C3',
      dark: '#0081C375',
      light: '#61c9ff',
      contrastText: '#fff',
      contrastTextDisabled: '#20282a',
    },
  },
  typography: tlkTheme.typography,
  zIndex: tlkTheme.zIndex,
};

const danfossTheme = {
  palette: {
    ...tlkTheme.palette,
    common: {
      black: '#000',
      white: '#fff',
      blue: '#46B9F3',
      red: '#E2000F',
      green: '#1AC632',
    },
    primary: {
      main: '#686868',
      contrastText: '#fff',
      contrastTextDisabled: '#959a9c', // better use #303030, bur requires some changes
    },
    secondary: {
      main: '#303030',
      contrastText: '#fff',
      contrastTextDisabled: '#969696',
    },
    tertiary: {
      main: '#efefef',
      contrastText: '#303030',
      contrastTextDisabled: '#2B3639',
    },
    highlight: {
      main: '#E2000F',
      dark: '#B6000F',
      light: '#D3785E',
      contrastText: '#fff',
      contrastTextDisabled: '#869098',
    },
    error: {
      main: '#C54A34',
      dark: '#B6000F',
      light: '#D3785E',
      contrastText: '#fff',
      contrastTextDisabled: '#151a1c',
    },
    warning: {
      main: '#FE8A01',
      dark: '#FE8A01',
      light: '#F1C40F',
      contrastText: '#151a1c',
    },
    success: {
      main: '#1AC632',
      dark: '#1AC632',
      light: '#1AC632',
      contrastText: '#fff',
    },
  },
  typography: { ...tlkTheme.typography },
  zIndex: tlkTheme.zIndex,
};

const neasimTheme = {
  palette: {
    ...tlkTheme.palette,
    highlight: {
      main: '#006B9D',
      dark: '#006B9D75',
      light: '#009be4',
      contrastText: '#fff',
      contrastTextDisabled: '#686868',
    },
  },
  typography: tlkTheme.typography,
  zIndex: tlkTheme.zIndex,
};

const kautexTheme = {
  palette: {
    ...tlkTheme.palette,
    highlight: {
      main: '#008FCC',
      dark: '#008FCC',
      contrastText: '#fff',
      contrastTextDisabled: '#696969',
    },
  },
  typography: tlkTheme.typography,
  zIndex: tlkTheme.zIndex,
};

const vaillantTheme = {
  palette: {
    ...tlkTheme.palette,
    highlight: {
      main: '#08947c',
      dark: '#08947c',
      light: '#0bd7b4',
      contrastText: '#fff',
      contrastTextDisabled: '#20282a',
    },
    tertiary: { ...tlkTheme.palette.tertiary, contrastHighlight: '#08947c' },
    quaternary: { ...tlkTheme.palette.quaternary, contrastHighlight: '#08947c' },
  },
  typography: tlkTheme.typography,
  zIndex: tlkTheme.zIndex,
};

const weisstechnikTheme = {
  palette: {
    ...tlkTheme.palette,
    primary: {
      main: '#fff',
      contrastText: '#000000',
      contrastTextDisabled: '#777777',
    },
    secondary: {
      main: '#f8f4f4',
      contrastText: '#000000',
      contrastTextDisabled: '#777777',
    },
    tertiary: {
      main: '#f8f4f4',
      contrastText: '#000000',
      contrastTextDisabled: '#777777',
    },
    quaternary: {
      main: '#C1C1C1',
      contrastHighlight: '#1e2865',
    },
    highlight: {
      main: '#00204E',
      dark: '#00204E',
      light: '#00204E',
      contrastText: '#fff',
      contrastTextDisabled: '#20282a',
    },
    text: {
      secondary: '#00204E',
    },
  },
  typography: tlkTheme.typography,
  zIndex: tlkTheme.zIndex,
};

const bdrsimTheme = {
  ...tlkTheme,
  palette: {
    ...tlkTheme.palette,
    highlight: {
      main: '#548ade',
      dark: '#1951a075',
      light: '#548ade',
      contrastText: '#fff',
      contrastTextDisabled: '#20282a',
    },
  },
  typography: tlkTheme.typography,
  zIndex: tlkTheme.zIndex,
};

const virtualhousesimTheme = {
  ...tlkTheme,
  palette: {
    ...tlkTheme.palette,
    primary: {
      main: '#fff',
      contrastText: '#000000',
      contrastTextDisabled: '#a39c95',
    },
    secondary: {
      main: '#f6f6f6',
      contrastText: '#000000',
      contrastTextDisabled: '#a39c95',
    },
    highlight: {
      main: '#3957a4',
      dark: '#3957a4',
      light: '#3957a4',
      contrastText: '#fff',
      contrastTextDisabled: '#20282a',
    },
    tertiary: { ...tlkTheme.palette.tertiary, contrastHighlight: '#3957a4' },
    quaternary: { ...tlkTheme.palette.quaternary, contrastHighlight: '#3957a4' },
  },
  typography: tlkTheme.typography,
  zIndex: tlkTheme.zIndex,
};

const sbcsimTheme = {
  ...tlkTheme,
};

const thewasimTheme = {
  palette: {
    ...tlkTheme.palette,
    primary: {
      main: '#fff',
      contrastText: '#000000',
      contrastTextDisabled: '#777777',
    },
    secondary: {
      main: '#eef0f0',
      contrastText: '#000000',
      contrastTextDisabled: '#777777',
    },
    tertiary: {
      main: '#eef0f0',
      contrastText: '#000000',
      contrastTextDisabled: '#777777',
    },
    quaternary: {
      main: '#eef0f0',
      contrastHighlight: '#981e32',
    },
    highlight: {
      main: '#981e32',
      dark: '#981e32',
      light: '#981e32',
      contrastText: '#fff',
      contrastTextDisabled: '#20282a',
    },
    text: {
      secondary: '#981e32',
    },
  },
  typography: tlkTheme.typography,
  zIndex: tlkTheme.zIndex,
};

const diagramTheme = {
  ...tlkTheme,
  typography: {
    ...tlkTheme.typography,
    fontFamily: 'ambit, Helvetica, Arial, Verdana, sans-serif',
    fontFamilyBody: 'noto, Helvetica, Arial, Verdana, sans-serif',
  },
};

// /** default breakpoints in mui v5:
//  * xs, extra-small: 0px
//  * sm, small: 600px
//  * md, medium: 900px
//  * lg, large: 1200px
//  * xl, extra-large: 1536px
//  *  */
const createMuiTheme = (
  // make all the needed keys required
  themeObject: ThemeOptions & {
    palette: PaletteOptions & {
      primary: SimplePaletteColorOptions;
      secondary: SimplePaletteColorOptions;
      tertiary: SimplePaletteColorOptions;
      quaternary: SimplePaletteColorOptions;
      highlight: SimplePaletteColorOptions;
      error: SimplePaletteColorOptions;
    };
    typography: TypographyVariantsOptions;
  }
) => {
  const muiTheme = createTheme({
    ...themeObject,
    palette: {
      ...themeObject.palette,
      primary: {
        ...themeObject.palette.primary,
        contrastHighlight: themeObject.palette.primary.contrastHighlight || themeObject.palette.highlight.main,
        contrastError: themeObject.palette.primary.contrastError || themeObject.palette.error.main,
      },
      secondary: {
        ...themeObject.palette.secondary,
        contrastHighlight: themeObject.palette.secondary.contrastHighlight || themeObject.palette.highlight.main,
        contrastError: themeObject.palette.primary.contrastError || themeObject.palette.error.main,
      },
      tertiary: {
        ...themeObject.palette.tertiary,
        contrastHighlight: themeObject.palette.tertiary.contrastHighlight || themeObject.palette.highlight.main,
        contrastError: themeObject.palette.primary.contrastError || themeObject.palette.error.main,
      },
      quaternary: {
        ...themeObject.palette.quaternary,
        contrastHighlight: themeObject.palette.quaternary.contrastHighlight || themeObject.palette.highlight.main,
        contrastError: themeObject.palette.primary.contrastError || themeObject.palette.error.main,
      },
      background: {
        paper: themeObject.palette?.background?.paper || themeObject.palette.primary.main,
        default: themeObject.palette?.background?.default || themeObject.palette.secondary.main,
        light: themeObject.palette?.background?.light || themeObject.palette.tertiary.main,
      },
      text: {
        primary: themeObject.palette?.text?.primary || themeObject.palette.primary.contrastText,
        secondary: themeObject.palette?.text?.secondary || themeObject.palette.secondary.contrastText,
        disabled: themeObject.palette?.text?.disabled || themeObject.palette.primary.contrastTextDisabled,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: themeObject.palette.primary.main,
            color: themeObject.palette.primary.contrastText,
            fontFamily: themeObject.typography.fontFamily,
          },
          h3: {
            fontWeight: 400,
          },
        },
      },
    },
  });

  // To have access to the breakpoint functions, compose the final theme in a second step.
  // See https://mui.com/material-ui/customization/theming/#api
  const muiThemeWithHtml = createTheme(muiTheme, {
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            boxSizing: 'border-box',
            fontSize: 12,
            [muiTheme.breakpoints.up('md')]: {
              fontSize: 13,
            },
            [muiTheme.breakpoints.up('lg')]: {
              fontSize: 16,
            },
          },
        },
      },
    },
  });

  return responsiveFontSizes(muiThemeWithHtml);
};

// We are not using the ThemeContext because it does not work with our test environment.
const getTheme = () => {
  if (settings.product === 'airflowsim') {
    return createMuiTheme(airflowTheme);
  }

  if (settings.product === 'pluggsim') {
    return createMuiTheme(pluggsimTheme);
  }

  if (settings.product === 'neasim') {
    return createMuiTheme(neasimTheme);
  }

  if (settings.product === 'danfosssim') {
    return createMuiTheme(danfossTheme);
  }

  if (settings.product === 'zieglersim') {
    return createMuiTheme(zieglersimTheme);
  }

  if (settings.product === 'heatensim') {
    return createMuiTheme(heatensimTheme);
  }

  if (settings.product === 'logph' || settings.product === 'hx' || settings.product === 'mccabethiele') {
    return createMuiTheme(diagramTheme);
  }

  if (settings.product === 'kautexsim') {
    return createMuiTheme(kautexTheme);
  }

  if (settings.product === 'vaillantsim') {
    return createMuiTheme(vaillantTheme);
  }

  if (settings.product === 'weisstechniksim') {
    return createMuiTheme(weisstechnikTheme);
  }

  if (settings.product === 'bdrsim') {
    return createMuiTheme(bdrsimTheme);
  }

  if (settings.product === 'virtualhousesim') {
    return createMuiTheme(virtualhousesimTheme);
  }

  if (settings.product === 'sbcsim') {
    return createMuiTheme(sbcsimTheme);
  }

  if (settings.product === 'thewasim') {
    return createMuiTheme(thewasimTheme);
  }

  return createMuiTheme(tlkTheme);
};

const theme = getTheme();
export default theme;
